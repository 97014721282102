<template>
  <div>
    <select
      class="form-control"
      :id="id"
      :name="name"
      :disabled="disabled"
      :required="required"
    ></select>
  </div>
</template>
<script>
import $ from "jquery";
import "select2/dist/js/select2.full";
import "select2/dist/css/select2.min.css";

export default {
  name: "Select2",
  data() {
    return {
      select2: null,
    };
  },
  emits: ["select", "update:modelValue"],
  props: {
    modelValue: [String, Array], // previously was `value: String`
    id: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    labelKey: {
      type: String,
      default: "",
    },
    valueKey: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    allowClear: {
      type: Boolean,
      default: false,
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    resetOptions: {
      type: Boolean,
      default: true,
    },
    settings: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    options: {
      handler(val) {
        this.setOption(val);
      },
      deep: true,
    },
    modelValue: {
      handler(val) {
        this.setValue(val);
      },
      deep: true,
    },
  },
  methods: {
    setOption(val = []) {
      let options = []

      if(this.resetOptions) {
        options.push({ id: "reset", text: this.placeholder });
      }
      
      let label = this.labelKey;
      let trackBy = this.valueKey;
      if (label && val instanceof Array) {
        val = $.map(val, function (obj) {
          obj.id = trackBy ? obj[trackBy] : obj.id;
          obj.text = obj[label];
          return obj;
        });
      }

      Array.prototype.push.apply(options,val);

      this.select2.empty();
      this.select2.select2({
        placeholder: this.placeholder,
        ...this.settings,
        data: options,
      });
      this.setValue(this.modelValue);
    },
    setValue(val) {
      if (val instanceof Array) {
        this.select2.val([...val]);
      } else {
        this.select2.val([val]);
      }
      this.select2.trigger("change");
    },
  },
  mounted() {
    this.select2 = $(this.$el).find("select");
    
    this.select2.select2({
        placeholder: this.placeholder,
        ...this.settings,
        data: [],
        allowClear: this.allowClear,
        closeOnSelect: this.closeOnSelect,
      })
      .on("select2:select select2:unselect", (ev) => {
        if (this.select2.val() === "reset") {
          this.select2.val("");
        }
        this.$emit("update:modelValue", this.select2.val());
        this.$emit("select", ev["params"]["data"]);
      });

    this.select2.on("select2:open", () => {
      $(".select2-search__field")[0].focus();
    });

    this.setOption(this.options);

    this.setValue(this.modelValue);
  },
  beforeUnmount() {
    this.select2.select2("destroy");
  },
};
</script>